import React, { FC, useState } from 'react'
import { useForm } from 'react-hook-form'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import Layout from 'components/Layout'
import { Grid, Title, Text, Button, Input } from 'components/theme'
import { Box } from 'styles/globalStyle'
import {
  AppointmentData,
  AppointmentPageTypes,
} from 'types/appointmentPageTypes'
import Checkbox from 'components/theme/Checkbox'
import CentersSelect from 'components/CentersSelect'
import SEO from 'components/SEO'
import { addContact, sendEmail } from 'helpers/http'
import { TextType } from 'types/dataTypes'

type AppointmentPageParams = {
  data: AppointmentPageTypes
  centers: {
    nodes: {
      data: {
        title: TextType
        email: TextType
      }
    }[]
  }
}

const AppointmentPage: FC<AppointmentPageParams> = ({ data }) => {
  const [isLoading, setLoading] = useState(false)
  const [showSuccess, setSuccess] = useState(false)
  const [showError, setError] = useState(false)
  const { title_meta, description_meta, title, subtitle, image } =
    data.prismicAppointmentPage.data
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    defaultValues: {
      lastName: '',
      firstName: '',
      email: '',
      phoneNumber: '',
      center: '',
      centerName: '',
      // conditions: false,
      newsletter: false,
    },
  })
  const submitForm = async (data: AppointmentData) => {
    setLoading(true)

    const contactInfo = {
      Name: `${data.firstName} ${data.lastName}`,
      Email: data.email,
      IsExcludedFromCompaigns: !data.newsletter,
      Properties: [
        {
          Name: 'phone',
          Value: data.phoneNumber,
        },
        {
          Name: 'audipluscenter',
          Value: data.center,
        },
      ],
    }
    const contactReq = await addContact(contactInfo)

    if (contactReq && contactReq.data.isSuccessStatusCode) {
      const emailData = {
        TemplateId: 3944226,
        DestinationInfo: {
          Email: data.center,
          Name: `${data.firstName} ${data.lastName}`,
        },
        Variables: {
          customer: `${data.firstName} ${data.lastName}`,
          email: data.email,
          lastname: data.lastName,
          firstname: data.firstName,
          phonenumber: data.phoneNumber,
          centername: data.centerName,
        },
      }

      const emailReq = await sendEmail(emailData)

      if (emailReq && emailReq.data.isSuccessStatusCode) {
        setSuccess(true)
        setError(false)
        reset()
      } else {
        setSuccess(false)
        setError(true)
      }
    } else {
      setSuccess(false)
      setError(true)
    }

    setLoading(false)
  }

  return (
    <Layout onlyFooter>
      <SEO title={title_meta.text} description={description_meta.text} />
      <Grid columns={['1fr', '', '', 'repeat(2, 1fr)']}>
        <Box display={['none', '', '', 'block']}>
          <GatsbyImage
            alt={image.alt}
            image={image.gatsbyImageData}
            style={{
              height: '100%',
              width: '100%',
              minHeight: '100vh',
            }}
            imgStyle={{
              objectPosition: 'top',
            }}
          />
        </Box>
        <Box padding={['2rem 1rem', '', '', '4rem 2rem', '', '6rem 6.5rem']}>
          <Title size="sm" mb="1.25rem">
            {title.text}
          </Title>
          <Text size="xl" mb="3rem">
            {subtitle.text}
          </Text>
          <form onSubmit={handleSubmit(submitForm)}>
            <Input
              label="Nom"
              register={register}
              value="lastName"
              placeholder="Entrer votre nom"
              errors={errors}
              mb="1.5rem"
              required
            />
            <Input
              label="Prénom"
              register={register}
              value="firstName"
              placeholder="Entrer votre prénom"
              errors={errors}
              mb="1.5rem"
              required
            />
            <Input
              type="email"
              label="Email"
              register={register}
              value="email"
              placeholder="Entrer votre adresse email"
              errors={errors}
              mb="1.5rem"
              required
            />
            <Input
              type="tel"
              label="Téléphone"
              register={register}
              value="phoneNumber"
              placeholder="Entrer votre numéro de téléphone"
              errors={errors}
              mb="1.5rem"
              required
            />
            <CentersSelect
              register={register}
              setValue={setValue}
              errors={errors}
            />
            {/* <Checkbox
              label="J'accepte les conditions générales d'utilisation"
              register={register}
              errors={errors}
              value="conditions"
              mt="1.5rem"
              required
            /> */}
            <Checkbox
              label="Je souhaite m’inscrire à la newsletter"
              register={register}
              errors={errors}
              value="newsletter"
              mt="1.5rem"
            />
            <Button
              type="gradient"
              mt="2rem"
              size="xl"
              width="100%"
              loading={isLoading}
            >
              Rappelez-moi
            </Button>
          </form>
          {showSuccess && (
            <Text mt="2rem" color="primary.700" fontWeight="500">
              Votre demande a bien été reçu par nos équipes, nous vous
              recontacterons très rapidement
            </Text>
          )}
          {showError && (
            <Text mt="2rem" color="error.500" fontWeight="500">
              Une erreur est survenue. Veuillez réesayer dans quelques instants.
            </Text>
          )}
        </Box>
      </Grid>
    </Layout>
  )
}

export const query = graphql`
  query {
    prismicAppointmentPage {
      data {
        title_meta {
          text
        }
        description_meta {
          text
        }
        title {
          text
        }
        subtitle {
          text
        }
        image {
          gatsbyImageData
          alt
        }
      }
    }
    centers: allPrismicCenters {
      nodes {
        data {
          title {
            text
          }
          email {
            text
          }
        }
      }
    }
  }
`

export default AppointmentPage
